import React, {useState} from "react";
import {Menu, Button, Text, rem, Switch, MultiSelect, Popover, Select, SegmentedControl} from '@mantine/core';
import SortIcon from '@mui/icons-material/Sort';
import {useDbContext} from "../contexts/dbContext";
import {ActionIcon} from "@mantine/core";
import {CheckmarkIcon} from "react-hot-toast";
import Tooltip from "@mui/material/Tooltip";
import {sendClick} from "./vuplexinteract";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";

export function DechManagerSortMenu(props) {
    const {sortType, setSortType} = props;
    return (
        <Menu shadow="md" width={200}>
            <Menu.Target>
                <Tooltip title={"Sorting options"}>
                    <ActionIcon size={35} variant="default" aria-label="Sort menu">
                        <SortIcon />
                    </ActionIcon>
                </Tooltip>
            </Menu.Target>
            <Menu.Dropdown>
                <Menu.Label>Total Cost</Menu.Label>
                <Menu.Item onClick={() => setSortType("CostAsc")} rightSection={(sortType === "CostAsc" ?  <CheckmarkIcon style={{ width: rem(14), height: rem(14) }} /> : <></>)}>
                    Ascending
                </Menu.Item>
                <Menu.Item onClick={() => setSortType("CostDesc")} rightSection={(sortType === "CostDesc" ?  <CheckmarkIcon style={{ width: rem(14), height: rem(14) }} /> : <></>)}>
                    Descending
                </Menu.Item>
                <Menu.Label>Name</Menu.Label>
                <Menu.Item onClick={() => setSortType("NameAsc")} rightSection={(sortType === "NameAsc" ?  <CheckmarkIcon style={{ width: rem(14), height: rem(14) }} /> : <></>)}>
                    Ascending
                </Menu.Item>
                <Menu.Item onClick={() => setSortType("NameDesc")} rightSection={(sortType === "NameDesc" ?  <CheckmarkIcon style={{ width: rem(14), height: rem(14) }} /> : <></>)}>
                    Descending
                </Menu.Item>
                <Menu.Label>Identity</Menu.Label>
                <Menu.Item onClick={() => setSortType("IdentityAsc")} rightSection={(sortType === "IdentityAsc" ?  <CheckmarkIcon style={{ width: rem(14), height: rem(14) }} /> : <></>)}>
                    Ascending
                </Menu.Item>
                <Menu.Item onClick={() => setSortType("IdentityDesc")} rightSection={(sortType === "IdentityDesc" ?  <CheckmarkIcon style={{ width: rem(14), height: rem(14) }} /> : <></>)}>
                    Descending
                </Menu.Item>
                <Menu.Label>Type</Menu.Label>
                <Menu.Item onClick={() => setSortType("TypeU")} rightSection={(sortType === "TypeU" ?  <CheckmarkIcon style={{ width: rem(14), height: rem(14) }} /> : <></>)}>
                    Type (Units First)
                </Menu.Item>
                <Menu.Item onClick={() => setSortType("TypeA")} rightSection={(sortType === "TypeA" ?  <CheckmarkIcon style={{ width: rem(14), height: rem(14) }} /> : <></>)}>
                    Type (Actions First)
                </Menu.Item>
            </Menu.Dropdown>
        </Menu>
    );
}

export function DechManagerFilterMenu(props) {
    const {exclusive, setExclusive, elementFilter, setElementFilter, typeFilter, setTypeFilter} = props;
    const [opened, setOpened] = useState(false);
    const {identities} = useDbContext();

    return (
        <Menu shadow="md" width={200} opened={opened} openDelay={100} closeDelay={400}>
            <Menu.Target>
                <Tooltip title={"Filters"}>
                    <ActionIcon onClick={() => { setOpened(!opened); }} size={35} variant="default" aria-label="Sort menu">
                        <DisplaySettingsIcon />
                    </ActionIcon>
                </Tooltip>
            </Menu.Target>
            <Menu.Dropdown>
                <Menu.Label>Identity</Menu.Label>
                <Menu.Item>
                    <div className={"flex w-full h-12 flex-row justify-around items-center"}>
                        <Switch
                            label="Identity Exclusive"
                            checked={exclusive}
                            onChange={(event) => setExclusive(event.currentTarget.checked)}
                        />
                    </div>
                    <MultiSelect
                        data={identities}
                        value={elementFilter}
                        onChange={setElementFilter}
                        color="gray"
                        clearable
                    />
                </Menu.Item>
                <Menu.Item>

                    <div className={"flex w-full h-12 flex-row justify-around items-center"}>
                        Type Filter
                    </div>
                    <SegmentedControl
                        value={typeFilter}
                        onChange={setTypeFilter}
                        data={[
                            { label: 'All', value: 'All' },
                            { label: 'Units', value: 'units' },
                            { label: 'Actions', value: 'actions' }
                        ]}
                    />
                </Menu.Item>
            </Menu.Dropdown>
        </Menu>
    );
}

export default function SortMenu(props) {
    const {sortType, setSortType, DisplayMode} = useDbContext();

    return (
            <Menu shadow="md" width={200}>
                <Menu.Target>
                    <Tooltip title={"Sorting options"}>
                    <ActionIcon size={35} variant="default" aria-label="Sort menu">
                        <SortIcon />
                    </ActionIcon>
                    </Tooltip>
                </Menu.Target>
                <Menu.Dropdown>
                    <Menu.Label>Total Cost</Menu.Label>

                    <Menu.Item onClick={() => setSortType("CostAsc")} rightSection={(sortType === "CostAsc" ?  <CheckmarkIcon style={{ width: rem(14), height: rem(14) }} /> : <></>)}>
                        Ascending
                    </Menu.Item>
                    <Menu.Item onClick={() => setSortType("CostDesc")} rightSection={(sortType === "CostDesc" ?  <CheckmarkIcon style={{ width: rem(14), height: rem(14) }} /> : <></>)}>
                        Descending
                    </Menu.Item>
                    <Menu.Label>Name</Menu.Label>
                    <Menu.Item onClick={() => setSortType("NameAsc")} rightSection={(sortType === "NameAsc" ?  <CheckmarkIcon style={{ width: rem(14), height: rem(14) }} /> : <></>)}>
                        Ascending
                    </Menu.Item>
                    <Menu.Item onClick={() => setSortType("NameDesc")} rightSection={(sortType === "NameDesc" ?  <CheckmarkIcon style={{ width: rem(14), height: rem(14) }} /> : <></>)}>
                        Descending
                    </Menu.Item>
                    <Menu.Label>Identity</Menu.Label>
                    <Menu.Item onClick={() => setSortType("IdentityAsc")} rightSection={(sortType === "IdentityAsc" ?  <CheckmarkIcon style={{ width: rem(14), height: rem(14) }} /> : <></>)}>
                        Ascending
                    </Menu.Item>
                    <Menu.Item onClick={() => setSortType("IdentityDesc")} rightSection={(sortType === "IdentityDesc" ?  <CheckmarkIcon style={{ width: rem(14), height: rem(14) }} /> : <></>)}>
                        Descending
                    </Menu.Item>
                    {(DisplayMode === "All" || DisplayMode === "Units")  &&
                        <>
                            <Menu.Label>Attack</Menu.Label>
                            <Menu.Item onClick={() => setSortType("AttackAsc")} rightSection={(sortType === "AttackAsc" ?  <CheckmarkIcon style={{ width: rem(14), height: rem(14) }} /> : <></>)}>
                                Ascending
                            </Menu.Item>
                            <Menu.Item onClick={() => setSortType("AttackDesc")} rightSection={(sortType === "AttackDesc" ?  <CheckmarkIcon style={{ width: rem(14), height: rem(14) }} /> : <></>)}>
                                Descending
                            </Menu.Item>
                            <Menu.Label>Defense</Menu.Label>
                            <Menu.Item onClick={() => setSortType("DefenseAsc")} rightSection={(sortType === "DefenseAsc" ?  <CheckmarkIcon style={{ width: rem(14), height: rem(14) }} /> : <></>)}>
                                Ascending
                            </Menu.Item>
                            <Menu.Item onClick={() => setSortType("DefenseDesc")} rightSection={(sortType === "DefenseDesc" ?  <CheckmarkIcon style={{ width: rem(14), height: rem(14) }} /> : <></>)}>
                                Descending
                            </Menu.Item>
                        </>}
                </Menu.Dropdown>
            </Menu>
    );
}

export function FilterMenu(props) {
    const {IdentityFilter, setIdentityFilter, identities, keywordFilter, keywords, setKeywordFilter, identity,
        setIdentity,
        keywordNot,
        setKeywordNot,
        keyword,
        setKeyword,
        identityNot,
        setIdentityNot} = useDbContext();
    const [opened, setOpened] = useState(false);

    return (
        <Popover width={300} position="bottom" opened={opened}
                 shadow="md">
            <Popover.Target>
                <Tooltip title={"Filter options"}>
                    <Button onClick={() => {
                        setOpened(!opened);
                        sendClick();
                    }} leftSection={<DisplaySettingsIcon />} color={opened ? "green" : "rgba(82, 82, 82, 1)"}>
                        Filters
                    </Button>
                </Tooltip>
            </Popover.Target>
            <Popover.Dropdown onMouseLeave={() => setOpened(false)}>
                <div className={"flex flex-col w-full h-auto pb-2"}>
                    <div className={"flex flex-row justify-center items-center h-8"}>Identity</div>
                    <div className={"flex w-full h-12 flex-row justify-around items-center"}>
                        <SegmentedControl
                            value={identity}
                            onChange={setIdentity}
                            data={[
                                {label: 'And', value: 'And'},
                                {label: 'Or', value: 'Or'},
                            ]}
                        />
                        <Switch
                            label={"Not"}
                            checked={identityNot}
                            onChange={(event) => {
                                setIdentityNot(event.currentTarget.checked);
                            }}
                        />
                    </div>
                    <MultiSelect
                        data={identities}
                        value={IdentityFilter}
                        onChange={setIdentityFilter}
                        color="gray"
                        clearable
                    />
                    <div
                        className={"flex flex-row justify-center items-center h-8 w-full mt-2 border-t border-gray-600"}>Keywords
                    </div>
                    <div className={"flex w-full h-12 flex-row justify-around items-center"}>
                        <SegmentedControl
                            value={keyword}
                            onChange={setKeyword}
                            data={[
                                {label: 'And', value: 'And'},
                                {label: 'Or', value: 'Or'},
                            ]}
                        />
                        <Switch
                            label={"Not"}
                            checked={keywordNot}
                            onChange={(event) => {
                                setKeywordNot(event.currentTarget.checked);
                            }}
                        />
                    </div>
                    <MultiSelect
                        data={keywords}
                        value={keywordFilter}
                        onChange={setKeywordFilter}
                        clearable
                    />
                </div>
            </Popover.Dropdown>
        </Popover>
    );
}