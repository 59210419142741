import {atom} from 'jotai';
import {supabase} from "../utils/supabase";

export const openPageAtom = atom(null);

export const sessionAtom = atom(null);


export const userIdAtom = atom((get) => get(sessionAtom)?.user?.id || '');

export const profileAtom = atom(null);
export const permissionsAtom = atom([]);
export const cardsOnlyAtom = atom(false);
export const matchIdAtom = atom('');
export const hideMenuAtom = atom(false);

export const isAdminAtom = atom((get) => {
    const session = get(sessionAtom);
    if(!session || !session.user) return false
    return supabase.rpc('is_admin').then(res => res.data).catch(() => false)
});

export const isInitialLoadAtom = atom(true);
export const loadingInitialAtom = atom(true);

export const discordAtom = atom(async (get) => {
    const session = get(sessionAtom);
    if (!session) {
        return null;
    }

    try {
        const { data, error } = await supabase.auth.getUserIdentities();
        if (error) {
            console.error("Error fetching user identities:", error);
            return null;
        }
        return data?.identities.find(identity => identity.provider === 'discord') || null;
    } catch (error) {
        console.error("Error fetching user identities:", error)
        return null
    }
});


