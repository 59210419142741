import {supabase} from "../utils/supabase";
import {useState} from "react";
import Button from "@mui/material/Button";
import toast from "react-hot-toast";
import {useAppContext} from "../contexts/appContext";
import {useDisclosure} from "@mantine/hooks";
import {Modal, TextInput, rem, PasswordInput} from "@mantine/core";
import { IconAt } from '@tabler/icons-react';
import { useApp } from '../hooks/useApp';
import {Navigate, useNavigate} from "react-router-dom";

export function ResetPasswordPanel(){
    const { session } = useApp();
    const [tempPassword, setTempPassword] = useState("");
    const [tempPassword2, setTempPassword2] = useState("");

    async function resetpass(){
        await supabase.auth.updateUser({ password: tempPassword }).then((result)=>{
            if(result.error){
                toast.error("Failed to change password, " + result.error, {position: "bottom-right"});
            } else {
                toast.success("Succesfully change password.", {position: "bottom-right"});
            }
        });
    }

    return (
        <div className="flex flex-col w-full h-full justify-center items-center">
            {session ?
            <div className={"flex flex-col w-1/4 h-1/3 justify-center items-center"}>
                <PasswordInput
                    label="Password"
                    value={tempPassword}
                    style={{width:'100%'}}
                    onChange={(e) => setTempPassword(e.target.value)}
                />
                <PasswordInput
                    label="Password Confirm"
                    value={tempPassword2}
                    style={{width:'100%'}}
                    onChange={(e) => setTempPassword2(e.target.value)}
                />
                <Button onClick={() => { if(tempPassword.length > 5 && tempPassword === tempPassword2) resetpass();}} >Request Reset</Button>
            </div> : <></>}
        </div>
    )
}

export default function AuthPanel(props){
    const { createProfile} = useApp();
    const [tempName, setTempName] = useState("");
    const [tempEmail, setTempEmail] = useState("");
    const [tempPassword, setTempPassword] = useState("");
    const [tempPassword2, setTempPassword2] = useState("");
    const [signupActive, setSignupActive] = useState(false);
    const [opened, { open, close }] = useDisclosure(false);
    const navigate = useNavigate();
    const [resetEmail, setResetEmail] = useState("");
    async function signInWithEmail() {
        if(tempPassword && tempEmail.includes("@")){
            const { data, error } = await supabase.auth.signInWithPassword({
                email: tempEmail,
                password: tempPassword,
            });
            if(error){
                toast.error(error.message, {position: "bottom-right"});
            } else {
                setTempEmail("");
                setTempPassword("");
                navigate("/");
                toast.success("Succesfully logged in, welcome back.", {position: "bottom-right"});
            }
        }
    }

    async function passReset(){
        await supabase.auth.resetPasswordForEmail(resetEmail, {
            redirectTo: 'https://play.manacaster.com/resetpass',
        })
        toast.success("Password reset email sent.", {position: "bottom-right"});
        setResetEmail("");
        close();
    }

    async function signInWithDiscord() {
        const { data, error } = await supabase.auth.signInWithOAuth({
            provider: 'discord',
        })
        if(error){
            toast.error(error.message, {position: "bottom-right"});
        } else {
            setTempEmail("");
            setTempPassword("");
            navigate("/");
            toast.success("Succesfully logged in, welcome back.", {position: "bottom-right"});
        }
    }

    async function signUpNewUser() {
        const { data, error } = await supabase.auth.signUp({
            email: tempEmail,
            password: tempPassword,
            options: {
                emailRedirectTo: 'https://play.manacaster.com/account',
                data: {
                    user_name: tempName,
                    full_name: tempName,
                }
            },
        })
        if(error){
            toast.error("Failed to sign up: " + error.message, {position: "bottom-right"});
        } else {
            createProfile(data.user.id, tempName,tempEmail).then(() => {
                setTempPassword2("");
                setTempName("");
                toast.success("The account was registered and a verification mail has been sent to your email address, please click the verification link.", {position: "bottom-right"});
                navigate("/");
                setSignupActive(false);
            });
        }
    }

    return (
        <div
            className={
                "flex flex-row h-full w-full items-center justify-center overflow-hidden z-40"
            }
        >
            <div
                className={"absolute hidden flex-row h-14 top-20 w-full bg-red-400 rounded-2xl justify-center items-center left-0"}>
                We have switched our auth system, please register a new account and let us know your email so we can link the
                old profile.
            </div>
            <div className={"my-auto max-w-[40rem] flex-col items-center justify-center w-full p-4 sm:px-5 border-2 border-[#4e4e4e] border-opacity-60 rounded bg-[#262626] bg-opacity-50"}>
                <div className="text-center">
                    <div className="mt-4">
                        <img
                            src="/logo192.png"
                            alt="Manacaster"
                            className="w-[300px] h-[70px] mx-auto"
                        />
                    </div>
                </div>
                <div className="mt-4 w-full flex justify-center items-center flex-col">
                    {signupActive && <><div className="flex flex-row h-10 text-lg items-center justify-center">
                        Display Name
                    </div>
                    <div className={"flex flex-row h-12 w-2/3 justify-center items-center"}>
                        <input value={tempName} onChange={(e) => setTempName(e.target.value)}
                               className={"bg-[#244939] h-full border border-[#51b78c] w-full px-4"}/>
                    </div></>}
                    <div className="flex flex-row h-10 text-lg items-center justify-center">
                        E-mail
                    </div>
                    <div className={"flex flex-row h-12 w-2/3 justify-center items-center"}>
                        <input type={"email"}  value={tempEmail} onChange={(e) => setTempEmail(e.target.value)}
                               className={"bg-[#244939] h-full border border-[#51b78c]  px-4 w-full"}/>
                    </div>
                    <div className="flex flex-row h-10 text-lg items-center justify-center">
                        Password
                    </div>
                    <div className={"flex flex-row h-12 w-2/3 justify-center items-center"}>
                        <input type={"password"}  value={tempPassword} onChange={(e) => setTempPassword(e.target.value)}
                            className={"bg-[#244939]  h-full border border-[#51b78c]  px-4 w-full"}/>
                    </div>
                    {signupActive && <><div className="flex flex-row h-10 text-lg items-center justify-center">
                        Password Confirm
                    </div>
                        <div className={"flex flex-row h-12 w-2/3 justify-center items-center"}>
                            <input type={"password"} value={tempPassword2} onChange={(e) => setTempPassword2(e.target.value)}
                                   className={"bg-[#244939] h-full  px-4 border border-[#51b78c] w-full"}/>
                        </div></>}

                    <div className={"flex flex-row h-16 justify-center items-center"}>
                        {signupActive ? <Button  onClick={() => {signUpNewUser();}} className={"w-20 h-12"} size={"large"}>Register</Button> :
                            <Button onClick={() => {signInWithEmail();}} className={"w-20 h-12"} size={"large"}>Login</Button>}
                    </div>
                    <Button className={"btn-ghost w-auto h-12"} onClick={() => {signInWithDiscord();}}>
                        Discord Login
                    </Button>
                    {!signupActive ? <div className={"flex flex-col h-20 gap-2 justify-center items-center"}>
                        Don't have an account yet? <p
                        className={"text-emerald-500 hover:underline hover:opacity-80"}
                        onClick={() => {
                            setSignupActive(true);
                        }}> Sign up here</p>
                    </div> : <div className={"flex flex-col h-20 gap-2 justify-center items-center"}>
                        Already have an account? <p
                        className={"text-emerald-500 hover:underline hover:opacity-80"}
                        onClick={() => {
                            setSignupActive(false);
                        }}> Log in here</p>
                    </div>}
                    <div className={"flex flex-row h-20 w-full justify-between items-between px-6"}>
                        <Button>Terms</Button>
                        <Button onClick={open}>Forgot Password</Button>
                        <Button>Privacy</Button>
                    </div>
                </div>
            </div>
            <Modal opened={opened} onClose={close} title="Password Reset">
                <TextInput
                    leftSectionPointerEvents="none"
                    leftSection={<IconAt style={{ width: rem(16), height: rem(16) }} />}
                    label="Your email"
                    value={resetEmail}
                    onChange={(e) => setResetEmail(e.target.value)}
                    placeholder="Your email"
                />
                <Button onClick={() => {passReset();}} >Request Reset</Button>
            </Modal>
        </div>
    )

}