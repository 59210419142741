import React, {Suspense, useEffect, useState} from "react";
import {Chip} from "@mui/material";
import {useSocial} from "../hooks/useSocial";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import {useAtom} from "jotai/index";
import {allPlayersAtom} from "../atoms/adminAtoms";
import {supabase} from "../utils/supabase";
import toast from "react-hot-toast";
import {Button} from "@mantine/core";

export function OnlinePlayersTable(props) {
    const {users} = useSocial();
    const [allPlayers, setAllPlayers] = useAtom(allPlayersAtom);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if(!loaded) getplayers().then(()=> setLoaded(true))
    }, [loaded]);

    async function getplayers() {
        try {
            const { data, error } = await supabase
                .from('profiles')
                .select()
            if(error){

            } else {
                let profs = data;
                profs.sort((a, b) => isOnline(a.user_id) < isOnline(b.user_id) ? 1 : -1);
                setAllPlayers(profs)
            }
        } catch (error) {

        }
    }

    async function SyncGold(){
        for(let i = 0; i < allPlayers.length; i++){
            const { error } = await supabase.from('gold').upsert({
                user_id: allPlayers[i].user_id,
                amount: allPlayers[i].gold,
                log: allPlayers[i].goldlog,
            });
        }
        toast.success('Synced gold to new table for ', {
            duration: 4000,
            position: 'bottom-right',
        });
    }

    function isOnline(str) {
        let found = false;
        if (users) {
            for (let i = 0; i < users.length; i++) {
                if (users[i].id === str) found = true;
            }
            return found;
        } else return false;
    }

    function SortPlayers(str, desc) {
        let profs = [];

        allPlayers.forEach(el => profs.push(el));
        switch (str) {
            case "online":
                profs.sort((a, b) => isOnline(a.user_id) < isOnline(b.user_id) ? (desc ? -1 : 1) : (desc ? 1 : -1));
                setAllPlayers(profs);
                break;
            case "name":
                profs.sort((a, b) => a.username.toLowerCase() < b.username.toLowerCase() ? (desc ? -1 : 1) : (desc ? 1 : -1));
                setAllPlayers(profs);
                break;
            case "id":
                profs.sort((a, b) => a.user_id.toLowerCase() < b.user_id.toLowerCase() ? (desc ? -1 : 1) : (desc ? 1 : -1));
                setAllPlayers(profs);
                break;
            case "level":
                profs.sort((a, b) => a.level < b.level ? (desc ? -1 : 1) : (desc ? 1 : -1));
                setAllPlayers(profs);
                break;
            case "last":
                profs.sort((a, b) => a.lastlogin < b.lastlogin ? (desc ? -1 : 1) : (desc ? 1 : -1));
                setAllPlayers(profs);
                break;
            case "wins":
                profs.sort((a, b) => a.wins < b.wins ? (desc ? -1 : 1) : (desc ? 1 : -1));
                setAllPlayers(profs);
                break;
            case "losses":
                profs.sort((a, b) => a.losses < b.losses ? (desc ? -1 : 1) : (desc ? 1 : -1));
                setAllPlayers(profs);
                break;
            default:
                profs.sort((a, b) => a.online < b.online ? (desc ? -1 : 1) : (desc ? 1 : -1));
                setAllPlayers(profs);
        }
    }

    return (
        <div className={"flex flex-row w-full h-full text-xs lg:text-sm bg-[#131a20] bg-opacity-40"}>
            <div className={"flex h-full flex-col w-full overflow-hidden"}>
                <div className="flex h-14 flex-row gap-2 w-full px-4 py-2">

                </div>
                <div className={"flex flex-col w-full overflow-hidden h-full p-4 lg:p-10"}>
                    <div className="flex overflow-y-auto w-full border border-[#51b78c] border-opacity-40">
                        <Suspense fallback={<div>Loading player data...</div>}>
                        <table className="table w-full table-xs table-pin-rows table-pin-cols justify-center items-center">
                            <PlayersHeader SortPlayers={SortPlayers} />
                            <tbody>
                            {allPlayers && allPlayers.map((el, ind) =>
                                <PlayerItem isOnline={isOnline} key={ind} entry={el} />)}
                            </tbody>
                        </table>
                        </Suspense>
                    </div>
                </div>
            </div>
        </div>
    )
}

function PlayersHeader(props) {
    const {SortPlayers} = props;
    return (
        <thead>
        <tr>
            <th>State <ArrowDropDownIcon onClick={()=> SortPlayers("online", true)}/> <ArrowDropUpIcon onClick={()=> SortPlayers("online", false)}/> </th>
            <th>ID<ArrowDropDownIcon onClick={()=> SortPlayers("id", true)}/> <ArrowDropUpIcon onClick={()=> SortPlayers("id", false)}/></th>
            <th>Name<ArrowDropDownIcon onClick={()=> SortPlayers("name", true)}/> <ArrowDropUpIcon onClick={()=> SortPlayers("name", false)}/></th>
            <th>Level<ArrowDropDownIcon onClick={()=> SortPlayers("level", true)}/> <ArrowDropUpIcon onClick={()=> SortPlayers("level", false)}/></th>
            <th>Last Login<ArrowDropDownIcon onClick={()=> SortPlayers("last", true)}/> <ArrowDropUpIcon onClick={()=> SortPlayers("last", false)}/></th>
            <th>Wins<ArrowDropDownIcon onClick={()=> SortPlayers("wins", true)}/> <ArrowDropUpIcon onClick={()=> SortPlayers("wins", false)}/></th>
            <th>Losses<ArrowDropDownIcon onClick={()=> SortPlayers("losses", true)}/> <ArrowDropUpIcon onClick={()=> SortPlayers("losses", false)}/></th>
        </tr>
        </thead>
    )
}

const playerentrystyle = "flex-row text-center justify-center items-center border-r border-[#51b78c] border-opacity-40";

function PlayerItem(props) {
    const {entry, isOnline} = props;
    return (
        <tr className={"border border-[#51b78c] border-opacity-40"}>
            <td className={playerentrystyle}>{isOnline(entry.user_id) ? <Chip label="Online" color="primary"/> : <Chip label="Offline" color="error"/>}</td>
            <th className={playerentrystyle}>{entry.user_id}</th>
            <td className={playerentrystyle}>{entry.username}</td>
            <td className={playerentrystyle}>{entry.level}</td>
            <td className={playerentrystyle}>{entry.lastlogin}</td>
            <td className={playerentrystyle}>{entry.wins}</td>
            <td className={playerentrystyle}>{entry.losses}</td>
        </tr>
    )
}